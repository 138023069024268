import http from '@/utils/http'

export const getNGApi = () => {
  return http.get('/auth/srp/parameters', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const getAuthChallenge = (username) => {
  return http.get('/auth/challenge', {
    params: { username }
  })
}

export const signIn = (data) => {
  return http.post('/auth/sign-in', data)
}

export const updateFCMToken = (token) => {
  return http.post(`/user/customer/fcm-token/${token}`)
}
