import http from '@/utils/http'

export const getWallets = () => {
  return http.get('/wallets')
}

export const createWalletApi = (data) => {
  return http.post('/wallets', data)
}

export const getWalletAccounts = () => {
  return http.get('/wallets/accounts')
}
